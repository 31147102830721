$(document).ready(function(){
    
    var windowWidth = $(window).width();


    // =========
    // RESPONSIVE FOOTER MENU
    // =========
    $(".footer-title").on("click",function(){
        if(windowWidth<767.98){
            $(this).siblings(".list-link").toggleClass("show");
        }
    })
    // =========
    // END OF RESPONSIVE FOOTER MENU
    // =========
    // =========
    // FAQ ANIMATION
    // =========
    function faqAnimationInit(){
        TweenMax.set("#background",{
            x:400,
        })
        TweenMax.set("#calendar",{
            x:460,
        })
        TweenMax.set("#phone",{
            y:-600,
        })
    }
    faqAnimationInit();
    // =========
    // END OF FAQ ANIMATION
    // =========
    
        // =========
        // WELCOME ICON ANIMATION IN HOME PAGE
        // ========
        // CREATED USING LOTTIE
        var welcomeAnimations = [];
        for (let index = 1; index <= 4; index++) {
            if($("#welcome-icon-"+index).length > 0){
                welcomeAnimations[index]=lottie.loadAnimation({
                    container: document.querySelector("#welcome-icon-"+index), // the dom element that will contain the animation
                    renderer: 'canvas',
                    loop: false,
                    autoplay: false,
                    path: 'assets/animation/welcome-'+index+'/data.json' // the path to the animation json
                });
            }
            
        }

        // END OF WELCOME ICON ANIMATION
        // ===========
        


        // ==========
        // ON SCROLL ANIMATION
        // ==========
        var scrollElementAnimate = [
            {
                "container" : "body",
                "childElement" : ".header-default"
            },
            {
                "container" : "body",
                "childElement" : ".welcome-icons"
            },
            {
                "container" : "body",
                "childElement" : ".map-wrapper"
            },
            {
                "container" : "body",
                "childElement" : ".easybook"
            },
            {
                "container" : "body",
                "childElement" : ".how-it-works-list"
            },
            {
                "container" : "body",
                "childElement" : ".header-content"
            },
            {
                "container" : "body",
                "childElement" : ".header-faq"
            }
        ];
        function scrollAnimation(){
            scrollElementAnimate.forEach(function(dt){
                var containerElement = $("body");
                var containerMonitor = scrollMonitor.createContainer(containerElement);
                var childElement = $(dt.childElement);
                var elementWatcher = scrollMonitor.create(childElement);
    
                elementWatcher.enterViewport(function(){
                    if(dt.childElement==".header-default"){
                        setTimeout(function(){
                            $(dt.childElement).addClass("show");
                            jQuery(function ($) {
                                // custom formatting example
                                $('.counter-animate').data('countToOptions', {
                                formatter: function (value, options) {
                                    return value.toFixed(options.decimals).replace(/\B(?=(?:\d{3})+(?!\d))/g, ',');
                                }
                                });
                                
                                // start all the timers
                                $('.counter-animate').each(count);  
                                
                                function count(options) {
                                var $this = $(this);
                                options = $.extend({}, options || {}, $this.data('countToOptions') || {});
                                $this.countTo(options);
                                }
                            });
                        },500);
                    }else if(dt.childElement == ".welcome-icons"){
                        var welcomeIcons = $(".welcome-icons").find(".welcome-icon-wrapper");
                        var counter = 1;
                        welcomeIcons.each(function(){
                            var $this = $(this);
                            
                            
                            setTimeout(function(){
                                $this.addClass("in");
                                
                                
                            },500*counter);
                            
                            if(counter==1){
                                setTimeout(function(){
                                    welcomeAnimations[1].play();
                                },500)
                            }else if(counter==2){
                                setTimeout(function(){
                                    welcomeAnimations[2].play();
                                },500*counter)
                            }else if(counter==3){
                                setTimeout(function(){
                                    welcomeAnimations[3].play();
                                },500*counter)
                            }else if(counter==4){
                                setTimeout(function(){
                                    welcomeAnimations[4].play();
                                },500*counter)
                            }
    
                            counter++;
                        })
                    }else if(dt.childElement == ".map-wrapper"){
                        var mapTexts = $(".map-wrapper .list-thick-o").find("li");
                        var counter = 1;
                        var animateMap = lottie.loadAnimation({
                            container: document.querySelector(".map-animation"), // the dom element that will contain the animation
                            renderer: 'canvas',
                            loop: true,
                            autoplay: true,
                            path: 'assets/animation/map.json' // the path to the animation json
                        });
                        animateMap.setSpeed(2.5)
                        mapTexts.each(function(){
                            var $this = $(this);
                            setTimeout(function(){
                                $this.addClass("in");
                            },500*counter);
                            counter++;
                        })
                        setTimeout(function(){
                            $(dt.childElement).find('.btn').addClass("in");
                        },500*counter)
                        
                    }else if(dt.childElement == ".easybook"){
                        $(".easybook-illustration").addClass("in");
                        var cards = $(".easybook .easybook-list").find(".card");
                        var counter = 1;
                        cards.each(function(){
                            var $this = $(this);
                            setTimeout(function(){
                                $this.addClass("in");
                            },500*counter);
                            counter++;
                        })
                    }else if(dt.childElement == ".how-it-works-list"){
                        TweenMax.to(".hiw-illustration-wrapper + p",0.3,{
                            opacity : 1,
                            ease : Ease.easeOut
                        })

                        var TL = new TimelineMax();
                        TL.set(".hiw-illustration-wrapper.hiw-1 .hiw-ornament",{
                            scale : 0.2,
                            y: 190,
                            autoAplha:0,
                            svgOrigin : "110% 0"
                        })
                        TL.set(".hiw-illustration-wrapper.hiw-1 .hiw-green-thick",{
                            scale:0,
                            svgOrigin : "138% 66%"
                        })
                        TL.set(".hiw-illustration-wrapper.hiw-5 .hiw-green-thick",{
                            scale:0,
                            svgOrigin : "138% 16%"
                        })
                        TL.set(".hiw-illustration-wrapper.hiw-2 .hiw-green-thick",{
                            scale:0,
                            svgOrigin : "173% 40%"
                        })
                        TL.set(".hiw-illustration-wrapper.hiw-2 #hiw-2-hiw-2-ornament-1",{
                            scale : 0,
                            y: 90,
                            autoAplha:0,
                            svgOrigin : "0% 0"
                        })
                        TL.set(".hiw-illustration-wrapper.hiw-2 #hiw-2-hiw-2-ornament-2",{
                            scale : 0.2,
                            x: 90,
                            autoAplha:0,
                            svgOrigin : "0% 0"
                        })
                        
                        TL.set("#hiw-3-hand",{
                            x: -70
                        })

                        TL.to('.hiw-illustration-wrapper',0.3,{
                            opacity: 1,
                            ease : Ease.easeOut
                        })
                        TL.to(".hiw-illustration-wrapper .hiw-ornament",0.6,{
                            x : 0,
                            y: 0,
                            
                            scale : 1,
                            autoAlpha: 1,
                            ease : Ease.easeOut,
                        })
                        TL.to(".hiw-illustration-wrapper .hiw-green-thick",0.3,{
                            scale : 1,
                            ease : Back.easeOut
                        })
                        TweenMax.to("#hiw-3-hand",0.6,{
                            x : 0,
                            ease : Ease.easeOut
                        })
                        TweenMax.to("#hiw-3-gear",0.6,{
                            svgOrigin : "19% 11%",
                            rotation : 180,
                            ease : Ease.easeOut
                        })
                    }else if(dt.childElement == ".header-content"){
                        $(dt.childElement).addClass("show");
                        $(dt.childElement).find(".fade").addClass("show");
                    }else if(dt.childElement == ".header-faq"){
                        
                        var phoneIn = TweenMax.to("#phone",0.8,{
                            y:0,
                            opacity:1,
                            ease:Ease.easeOut,
                        })
                        var calendarIn = TweenMax.to("#background,#calendar",0.8,{
                            x:0,
                            opacity:1,
                            ease:Ease.easeOut,
                        })

                        var tl = new TimelineMax();

                        tl.add([phoneIn,calendarIn],"+=0", "stagger", 0);
                        tl.to("#orang-1,#orang-2",0.8,{
                            autoAlpha:1,
                            ease:Ease.easeOut
                        })
                    }
    
    
                })
            })
        }

    // END OF SCROLL ANIMATION
    // ===========


    
    

  
   
    // =========
    // TESTIMONIALS ANIMATION
    // ==========
    // Get titles from the DOM
    var testimonial  = $(".testimonials-list");

    if (testimonial.length) {

        testimonial.slick({
            autoplay: true,
            arrows: false,
            dots: false,
            slidesToShow:3,
            centerMode :true,
            centerPadding: "120px",
            draggable: false,
            infinite: true,
            pauseOnHover: false,
            swipe: false,
            touchMove: false,
            vertical: true,
            speed: 1000,
            autoplaySpeed: 2000,
            useTransform: true,
            cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
            adaptiveHeight: true,
        });

        // Manually refresh positioning of slick
        testimonial.slick('slickPlay');
    };

    // END OF TESTIMONIAL ANIMATION
    // =========
    // =========
    // RECENT USER ANIMATIO
    // ========
    var recentSignup  = $(".recent-signup-list");

    if (recentSignup.length) {

        recentSignup.each(function(){
            var $this = $(this);
            var delay = $(this).data('delay');
            if(!delay){
                delay = 0;
            }

            $(this).slick({
                autoplay: true,
                arrows: false,
                dots: false,
                slidesToShow:2,
                centerMode :true,
                centerPadding: "20px",
                draggable: false,
                infinite: true,
                pauseOnHover: false,
                swipe: false,
                touchMove: false,
                vertical: true,
                speed: 1000,
                autoplaySpeed: 2000,
                useTransform: true,
                cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
                adaptiveHeight: true,
            });
    
            // Manually refresh positioning of slick
            setTimeout(function(){
                $this.slick('slickPlay');
            },delay);
        })
    };
    // END OF RECENT USER ANIMATION
    // ============

    // ===========
    //NAV SCROLL TO ACTIOn
    // ===========
    $(".nav-link-slide").on("click",function(){
        var target = $(this).attr("href"),
        target = target.replace("/","");
        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 2500);
    })
    // END OF NAV SCROLL TO ACTION
    // ==========


    // =========
    // CHECK IF DOCUMENT READY THEN LOAD ANIMATION\
    // =========
    var animate = false;
    var elementReady=setInterval(function(){
        if(document.readyState === 'ready' || document.readyState === 'complete') {
            clearInterval(elementReady);
            scrollAnimation();
        }
    },500)
    // END 
    // =========

    // =========
    // FORM SIGN UP ACTION
    // =========
    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }
    // $("input[name=email]").on("keyup",function(){
    //     var email = $(this).val();

    //     if(email == "invalid@gmail.com"){
    //         $(this).removeClass("is-invalid");
    //     }else{
    //         $(this).addClass("is-invalid");
    //     }
    // })
    $("#form-login input").on("keyup",function(){
        var actionDefault = $(this).parents("form").find(".action__default");
        var actionSubmit = $(this).parents("form").find(".action__submit");
        actionDefault.addClass("show");
        actionDefault.removeClass("hide");
        actionSubmit.addClass("hide");
        actionSubmit.removeClass("show");
        actionDefault.children(".btn").removeClass("btn-default-invert");
        actionDefault.children(".btn").addClass("btn-primary");
        actionDefault.children(".btn").removeAttr("disabled");
    })
    $("#form-login").on("submit",function(e){
        e.preventDefault();
        var actionDefault = $(this).find(".action__default"),
        actionSubmit = $(this).find(".action__submit"),
        actionSubmitInvalidPassword = $(this).find(".action__submit.invalid-password"),
        actionSubmitNeedVerify = $(this).find(".action__submit.need-verify"),
        multipleAccountEl = $(this).siblings(".multiple-account");
        
        var emailForm = $(this).find("input[name=email]");
        var email = emailForm.val();
        var passForm = $(this).find("input[name=password]"),
        pass = passForm.val();

        var loginDisable = false;

        const email_need_verify = "verify@gmail.com",
        email_invalid = "invalid@gmail.com",
        pass_invalid = "invalid",
        pass_invalid_fifth = "invalidfifth",
        email_valid  = "valid@gmail.com",
        email_multiple = "multiple@gmail.com";

        actionSubmit.removeClass("show");
        actionSubmit.addClass("hide");
        // actionSubmitInvalidPassword.removeClass("show");
        
        // actionSubmitInvalidPassword.addClass("hide");
        actionDefault.removeClass("hide");
        emailForm.removeClass("is-invalid");
        passForm.removeClass("is-invalid");
        actionSubmit.children(".btn").show();
        actionDefault.children("a").removeClass("hide");
        multipleAccountEl.addClass("hide");

        $(".invalid-feedback").removeClass("hide");
        if(validateEmail(email)){

            if(email == email_need_verify){
                actionDefault.removeClass("show");
                actionDefault.addClass("hide");
                actionSubmitNeedVerify.removeClass("hide");
                actionSubmitNeedVerify.addClass("show");



            }else if(email == email_invalid){
                emailForm.addClass("is-invalid");
                loginDisable = true;
            }else if(email == email_multiple && pass != pass_invalid && pass != pass_invalid_fifth){
                multipleAccountEl.removeClass("hide");
                actionDefault.addClass("hide");
            }
        }
        if(pass==pass_invalid){
            passForm.addClass("is-invalid");
            loginDisable = true;
        }else if(pass==pass_invalid_fifth){
            passForm.addClass("is-invalid");
            passForm.siblings(".invalid-feedback").hide();
            actionSubmit.removeClass("show");
            actionSubmitInvalidPassword.addClass("show");
            actionSubmitInvalidPassword.find("input").val(email);
            loginDisable = true;
            
        }


        if(loginDisable){
            actionDefault.children(".btn").removeClass("btn-primary");
            actionDefault.children(".btn").addClass("btn-default-invert");
            actionDefault.children(".btn").attr("disabled","true");
            
            
        }else{
            actionDefault.children(".btn").removeClass("btn-default-invert");
            actionDefault.children(".btn").addClass("btn-primary");
            actionDefault.children(".btn").removeAttr("disabled");
        }


    })


})